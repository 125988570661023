/* eslint-disable react/no-danger */

import React, { useState } from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import { PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { PrimaryButton } from '@bit/numudev.numu_lib.primary-button';
import LocationLogo from '../images/location.png';
import Text from '../lib/NewText/Text';
import Layout from '../components/ui/layout';
import DefaultLanguage from '../hoc/default-language';
import * as styles from '../style/templates/pages/job-detail.module.scss';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/style.scss';
import 'font-awesome/css/font-awesome.min.css';
import SEO from '../components/ui/seo';

const JobDetail = ({ pageContext, location, intl }) => {
  const currentPage = pageContext;
  const [isBottom, setBottom] = useState(false);
  const breakpoints = useBreakpoint();
  if (typeof window !== 'undefined') {
    window.onscroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        setBottom(true);
      } else {
        setBottom(false);
      }
    };
  }

  const removeExtraSpaces = (data) => {
    const replacedData = data.replaceAll(`<p><br></p>`, '');
    return replacedData;
  };
  return (
    <>
      <SEO page="career" language={pageContext.language} />
      <DefaultLanguage location={location}>
        <Layout location={location} language={currentPage.language} page="job-detail">
          <div className={[styles.containerRow, 'container'].join(' ')}>
            <div className="row">
              <div className="col-md-9 col-lg-9 col-xl-9">
                <div className="job-description">
                  <h2>{currentPage.title && currentPage.title}</h2>
                  <h5>{currentPage.team && currentPage.team.name}</h5>
                  <div className={styles.address}>
                    <div className={styles.location}>
                      <img src={LocationLogo} alt="address:" />
                    </div>
                    <Text style={{ color: '#727176', fontSize: '0.9rem' }}>
                      {currentPage.city && currentPage.city.name}
                      {currentPage.city && currentPage.city.name && ', '}
                      {currentPage.country && currentPage.country.name}.
                    </Text>
                  </div>
                  <div className="description-detail">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: removeExtraSpaces(currentPage.jobDescription)
                      }}
                    />
                    <div className="d-none d-md-block">
                      <PrimaryButton
                        text={intl.formatMessage({ id: `hr.applyNow` })}
                        size="big"
                        onClick={() => {
                          if (typeof window !== 'undefined') {
                            const url =
                              currentPage.country.name === 'Pakistan'
                                ? `/job-application-pk`
                                : `/job-application`;
                            window.open(url, '_blank');
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3 d-none d-md-block center">
                <PrimaryButton
                  id="apply-button"
                  text={intl.formatMessage({ id: `hr.applyNow` })}
                  size="big"
                  onClick={() => {
                    if (typeof window !== 'undefined') {
                      const url =
                        currentPage.country.name === 'Pakistan'
                          ? `/job-application-pk`
                          : `/job-application`;
                      window.open(url, '_blank');
                    }
                  }}
                />
              </div>
            </div>
            {breakpoints.md && !isBottom ? (
              <div
                id="btnApply"
                className="fixed-bottom"
                style={{ backgroundColor: '#fff', padding: `15px 20px 0` }}>
                <PrimaryButton
                  id="apply-button"
                  style={{ width: '100%' }}
                  text={intl.formatMessage({ id: `hr.applyNow` })}
                  size="big"
                  onClick={() => {
                    if (typeof window !== 'undefined') {
                      const url =
                        currentPage.country.name === 'Pakistan'
                          ? `/job-application-pk`
                          : `/job-application`;
                      window.open(url, '_blank');
                    }
                  }}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </Layout>
      </DefaultLanguage>
    </>
  );
};

JobDetail.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  pageContext: PropTypes.instanceOf(PageProps).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired
};

export default injectIntl(JobDetail);
